import { apiClient } from "@lobby/api-client";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAuth } from "@shared/lib";

import type { SendOptions } from "@lobby/api-client";

export const Chat = {
  useConversationList() {
    const { isAuth } = useAuth();

    return useQuery({
      enabled: isAuth,
      queryKey: ["Conversation.getList"],
      queryFn: async () => {
        const response = await apiClient.send({ endpoint: "Conversation.getList" });

        if (response.error) {
          return {
            ...response,
            result: [],
          };
        }

        return response;
      },
      select: (data) => data.result || [],
    });
  },
  useSendMessage() {
    const queryClient = useQueryClient();

    return useMutation({
      mutationKey: ["Conversation.sendMessage"],
      mutationFn: (params: SendOptions<"Conversation.sendMessage">["params"]) =>
        apiClient.send({
          endpoint: "Conversation.sendMessage",
          params,
        }),
      onSettled: async () =>
        await queryClient.invalidateQueries({ queryKey: ["Conversation.getMessages"] }),
    });
  },
  useConversationHistory(conversationId: number | undefined) {
    const { isAuth } = useAuth();

    return useInfiniteQuery({
      staleTime: 1000 * 30,
      refetchInterval: 1000 * 30,
      initialPageParam: 20,
      enabled: isAuth && conversationId !== undefined,
      queryKey: ["Conversation.getMessages", conversationId],
      queryFn: async ({ pageParam: limit }) => {
        const response = await apiClient.send({
          endpoint: "Conversation.getMessages",
          params: {
            conversationId: conversationId as number,
            startSeqNum: 0,
            limit,
          },
        });

        if (response.error) {
          response.result = response.result ?? [];
        }

        return response;
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        const preLastPageResult = allPages.length > 2 ? allPages[allPages.length - 2]?.result : [];
        const lastPageResult = lastPage.result;

        if (
          lastPageResult.length < 10 ||
          (allPages.length > 2 && lastPageResult.length === preLastPageResult.length)
        ) {
          return undefined;
        }

        return Math.min(100, lastPageParam + 10);
      },
      getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
        if (firstPageParam <= 1) {
          return undefined;
        }
        return firstPageParam - 1;
      },
    });
  },
};
