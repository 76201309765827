import { clsx } from "clsx";
import { useState } from "react";

import { Lobby } from "@entities/lobby";
import { SupportChatBtn } from "@features/support-chat-btn";

import { SupportChartWindow } from "./support-chat-window";

export function SupportChat({ className }: { className?: string }) {
  const [isMinimized, setIsMinimized] = useState(true);
  const { data: lobbyData } = Lobby.useLobby();

  const isConversationActive = lobbyData?.isConvActive ?? false;

  if (!isConversationActive) {
    return null;
  }

  return (
    <div className={clsx("relative max-w-full", className)}>
      {isMinimized ? (
        <SupportChatBtn
          className="absolute bottom-0 right-0"
          hasUnreadMessages={false}
          onClick={() => setIsMinimized(false)}
        />
      ) : (
        <SupportChartWindow onMinimized={() => setIsMinimized(true)} />
      )}
    </div>
  );
}
